/* 
***
BOOTSTRAP COLLAPSE - CHANGE FONT-COLOR AND OREINTATION OF CHEVRON
1.) ON CLICK OF COLLAPSING NODE ==> CHANGE HEADING COLOUR TO DARKBLUE
2.) ON CLICK OF COLLAPSING NODE ==> CHANGE CHEVRON POSITION FROM DOWN TO UP (ROTATE 180 DEGREES)
3.) ON CLICK AWAY FROM COLLAPSING NODE ==> CHANGE COLOR BACK TO DEFAULT (BLACK)
4.) ON CLICK AWAY FROM COLLAPSING NODE ==> CHANGE CHEVRON POSITION FROM UP TO DOWN (ROTATE 180 DEGREES)
***
*/
jQuery(document).ready(function() {
    if( jQuery('.cpc-accordion').length){

        jQuery('.cpc-accordion .collapse').on('show.bs.collapse', function () {
            // jQuery(this)[0].parentElement.childNodes[1].children[2].classList.add("cpc-accordion-caret-rotate");
            jQuery(this)[0].parentElement.childNodes[1].children[1].children[0].classList.add("cpc-accordion-caret-rotate");
            // console.log( jQuery(this)[0].parentElement.childNodes[1].children[1].children[0].classList.add("cpc-accordion-caret-rotate") );
        });
    
        jQuery('.cpc-accordion .collapse').on('hide.bs.collapse', function () {
            jQuery(this)[0].parentElement.childNodes[1].children[1].children[0].classList.remove("cpc-accordion-caret-rotate");
            // jQuery(this)[0].parentElement.childNodes[1].children[2].classList.remove("cpc-accordion-caret-rotate");
        });
      } 
});
