jQuery(document).ready(function( $ ) {

    var fileSelect              = $('.feum-file-upload-submit'),
        fileElement             = $('.js-feum-hidden-file-upload'),
        deletefileElementSrc    = $( '.feum-delete-img' ),
        colorPicker             = $('.cpc-color-picker-input');

    
    fileSelect.each( function(){
    
        $( this ).click( function(e){
            $( this )[0].parentElement.children[3].click();
        });
    });
    fileElement.change( function(e){
        var shorteninput = shortenInputLength( e.currentTarget.files[0].name );

        $( this )[0].parentElement.children[2].value = shorteninput;
        $( this )[0].parentElement.children[2].value = '';
        $( $(this).parent().children()[1] ).addClass('has-focus');
        $( $( this )[0].parentElement.children[0] ).find( 'span' ).html( shorteninput );
        $( $( this )[0].parentElement.children[0] ).removeClass( 'cpc-hidden' );
    });

    deletefileElementSrc.click( function(e){
        $( this )[0].parentElement.parentElement.children[4].value = '';
        $( $( this )[0].parentElement.parentElement.children[1] ).removeClass( 'has-focus' );
        $( $( this )[0].parentElement.parentElement.children[4] ).value = "";
        $( $( this )[0].parentElement.parentElement ).find('div').addClass( 'cpc-hidden' );
    });

    colorPicker.each( function(){

        $(this)[0].parentElement.children[0].style.backgroundColor = $(this)[0].parentElement.children[0].children[0].innerText.toString();
        
        $(this).colorpicker()
        .on('changeColor', function(e) {
            $(this)[0].parentElement.children[2].value = e.color.toString();
            $(this)[0].parentElement.children[0].children[0].textContent = e.color.toString();
            $(this)[0].parentElement.children[0].style.backgroundColor = e.color.toString();
        });

        
    });

    function shortenInputLength( input ){

        if( input.length > 18 ){
            input = input.substring(0, 18) + "...";
        }
        return input;
    }
})