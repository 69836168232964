jQuery(document).ready(function() {

    jQuery('input').each(function() {
  
        jQuery(this).on('focus', function() {
        jQuery(this).parent().parent('.gfield').addClass('active');
      });
  
      jQuery(this).on('blur', function() {
        if (jQuery(this).val().length == 0) {
          jQuery(this).parent().parent('.gfield').removeClass('active');
        }
      });
      
      if (jQuery(this).val() != ''){
        jQuery(this).parent().parent().addClass('active');
        jQuery(this).parent('.css').addClass('active');
      }
  
    });

   jQuery('textarea').each(function() {

      jQuery(this).on('focus', function() {
        jQuery(this).parent().parent().addClass('active');
      });
  
      jQuery(this).on('blur', function() {
        if (jQuery(this).val().length == 0) {
          jQuery(this).parent().parent().removeClass('active')
        }
      });
      
      if (jQuery(this).val() != '') jQuery(this).parent().parent().addClass('active');
  
    });

    jQuery('input').each(function() {

      jQuery(this).on('focus', function() {
        jQuery(this).parent().addClass('active');
      });
  
      jQuery(this).on('blur', function() {
        if (jQuery(this).val().length == 0) {
          jQuery(this).parent().removeClass('active')
        }
      });
      
      if (jQuery(this).val() != '') jQuery(this).parent('.css').addClass('active');
  
    });
    

    jQuery('.feum-cpc-form').each( function(){

      jQuery(this).find('input[type=text]').each(function(){
        // INITIAL PAGELOAD => CHECK FOR VALUE
        if( jQuery(this).val().length != '' ){
          jQuery(this).parent().children('label').addClass('has-focus');
        }

        jQuery(this).on('focus', function(){
          jQuery(this).parent().children('label').addClass('has-focus');

        });

        jQuery(this).on('blur', function(){
          if( jQuery(this).val().length == 0 ){

            jQuery(this).parent().children('label').removeClass('has-focus');
          }
          
        });



      });

      jQuery(this).find('input[type=password]').each(function(){
        // INITIAL PAGELOAD => CHECK FOR VALUE
        if( jQuery(this).val().length != '' ){
          jQuery(this).parent().children('label').addClass('has-focus');
        }

        jQuery(this).on('focus', function(){
          jQuery(this).parent().children('label').addClass('has-focus');

        });

        jQuery(this).on('blur', function(){
          if( jQuery(this).val().length == 0 ){

            jQuery(this).parent().children('label').removeClass('has-focus');
          }
          
        });



      });

    });

    jQuery('.cpc-account-form').each( function(){
      
      jQuery(this).find('input').each(function(){
        // INITIAL PAGELOAD => CHECK FOR VALUE
        if( jQuery(this).val().length != '' ){
          jQuery(this).parent().children('label').addClass('has-focus');
        }

        jQuery(this).on('focus', function(){

          jQuery(this).parent().children('label').addClass('has-focus');

        });

        jQuery(this).on('blur', function(){

          if( jQuery(this).val().length == 0 ){
            jQuery(this).parent().children('label').removeClass('has-focus');
          }
          
        });



      });

    });
  });