document.addEventListener("DOMContentLoaded", function(){
    
    let mobileIcon = document.querySelector('.cpc-mobile-menu-icon');
    let mobileOverlay = document.querySelector('.cpc-mobile-overlay');
    let body = document.querySelector('body');

    mobileIcon.addEventListener("click" , function(){
        this.classList.toggle("change");
        mobileOverlay.classList.toggle("shown");
        mobileOverlay.classList.toggle("d-none");
        body.classList.toggle("cpc-body-overflow-hidden");

    });

    if ( matchMedia ) {
        const mq = window.matchMedia("(max-width: 992px)");

        WidthChangeTablet(mq);
        mq.addListener(WidthChangeTablet);
        // WidthChangeTablet(mq);
    }

    function WidthChangeTablet(mq) {

        if( mq.matches === false ){
            mobileIcon.classList.remove( "change" );
            mobileOverlay.classList.remove( "shown" );
            body.classList.remove( "cpc-body-overflow-hidden" );
        }
    }

});