ThemeJS.Lazy_Images = (function ( $ ) {

    var observer;

    $(function () {
        var images = document.querySelectorAll('.lazy-image');
        var config = {
            // If the image gets within 100px in the Y axis, start the download.
            rootMargin: '100px 0px',
            threshold: 0.01
        };

        // The observer for the images on the page
        observer = new IntersectionObserver( onIntersection, config );
        for ( var i = 0; i < images.length; i++ ) {
            observer.observe( images[i] );
        };
    });

    function onIntersection( entries ) {
        // Loop through the entries
        for ( var i = 0; i < entries.length; i++ ) {
            var entry = entries[i];
            // Are we in viewport?
            if ( entry.intersectionRatio > 0 ) {
        
                // Stop watching and load the image
                observer.unobserve( entry.target );
                preloadImage( entry.target );
            }
        };
    }

    function preloadImage( target ) {
        var $target = $(target);
        $target.attr('src', $target.data().src );
    }

    return {};

})( jQuery );