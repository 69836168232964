/* 
***
INTROJS - DOCUMENTATION
1.) CREATE INTRO.JS OBJECT
2.) CLICK HELP ICON ==> INIT INTRO.JS
3.) IF GET PARAM IS SET ==> INIT INTRO.JS
***
*/

// console.log( introJs() );
// introJs().start();
let showTutorialParam = location.search.slice(1);

function deleteTutorialCookie() {
  document.cookie = "cpc_is_tutorial_complete=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

function markTutorialComplete() {
  deleteTutorialCookie();
  setTutorialCompleteFlag();
}

function startIntro(){
    var intro = introJs();
      intro.setOptions({
        steps: [
          { 
            intro: "Welcome!"
          },
          { 
            intro: "This is a tutorial to walk you through editing current deals and adding new ones, plus managing club-related information."
          },
          {
            element: document.querySelector('.cpc-deals-row'),
            intro: "These are the current deals associated to your club.",
            position: 'auto'
          },
          {
            element: document.querySelectorAll('.cpc-deals-row .col-md-4.grab')[0],
            intro: "Each card represents a deal associated to your club.",
            position: 'auto'
          },
          {
            element: document.querySelectorAll('.cpc-deals-row .cpc-deal-link')[0],
            intro: 'Each card has a link to edit that deal and related deal location information.',
            position: 'auto'
          },
          {
            element: document.querySelector('.cpc-manager-add-deal'),
            intro: "Click here to add a new deal. These forms are identical to the forms when you edit a deal.",
            position: 'auto'
          },
        ]
      });

      intro.start();

      intro.oncomplete(function() {
        //go to create deal screen
        window.location.href = document.querySelectorAll('.btn-add-deal')[0].href + '&second-tutorial-page';
      });

      intro.onexit(deleteTutorialCookie);
  }

  function introCreateDeal() {
    var intro = introJs();

    intro.setOptions({
      steps: [
        {
          element: document.querySelector('form[name="cpc_new_deal"]'),
          intro: "This is the form to create a brand new deal for your users.",
          position: 'left'
        },
        {
          element: document.querySelector('form[name="cpc_add_location"]'),
          intro: 'You can assign location to your deals here.s',
          position: 'auto'
        },
        {
          element: document.querySelector('.cpc-form-add-location'),
          intro: "You can manually add a location here.",
          position: 'auto'
        },
        {
          element: document.querySelector('.cpc-location-add-location'),
          intro: "Click here to manually add another location.",
          position: 'auto'
        },
        {
          element: document.querySelector('.cpc-location-open-modal'),
          intro: "Clicking here will open a modal to search for locations by address. The results will then auto-populate below.",
          position: 'auto'
        },
        {
          element: document.querySelector('form[name="cpc_edit_location"]'),
          intro: "You can edit location details once they are loaded. Information is by default grabbed by Google maps.",
          position: 'auto'
        },
        {
          element: document.querySelector('.feum-cpc-form-new-deal-submit'),
          intro: "Once you have finished entering content, click here to create the new deal.",
          position: 'auto'
        },
        {
          element: document.querySelector('.cpc-deal-back-link a'),
          intro: "Click here to go back to the list of deals.",
          position: 'auto'
        }
      ]
    });

    intro.start().oncomplete(function() {
      window.location.href = document.querySelector('.cpc-deal-back-link a').href + '?third-tutorial-page';
    });

    intro.onexit(deleteTutorialCookie);
  }

  // function IntroSecondPage(){
  //   var intro = introJs();
  //   intro.setOptions({
  //     steps: [
  //       {
  //         element: document.querySelector('form[name="cpc_edit_deal"]'),
  //         intro: "This the the current deal information",
  //         position: 'right'
  //       },
  //       {
  //         element: document.querySelector('form[name="cpc_edit_deal"]'),
  //         intro: "You can edit the details here",
  //         position: 'left'
  //       },
  //       {
  //         element: document.querySelector('form[name="cpc_edit_location"]'),
  //         intro: 'These are the locations assigned to a deal.',
  //         position: 'auto'
  //       },
  //       {
  //         element: document.querySelector('.cpc-form-add-location'),
  //         intro: "You can manually add a location here.",
  //         position: 'auto'
  //       },
  //       {
  //         element: document.querySelector('.cpc-location-add-location'),
  //         intro: "Click here to manually add another location.",
  //         position: 'auto'
  //       },
  //       {
  //         element: document.querySelector('.cpc-form-add-location'),
  //         intro: "You can manually add a location here.",
  //         position: 'auto'
  //       },
  //       {
  //         element: document.querySelector('.cpc-location-open-modal'),
  //         intro: "Clicking here will open a modal to search for locations by address. The results will then auto-populate below.",
  //         position: 'auto'
  //       },
  //       {
  //         element: document.querySelector('form[name="cpc_edit_location"]'),
  //         intro: "You can edit location details once they are loaded. Information is by default grabbed by Google maps.",
  //         position: 'auto'
  //       },
  //       {
  //         element: document.querySelector('.feum-cpc-form-new-deal-submit'),
  //         intro: "Once you have finished your updates, click here to save the the updates.",
  //         position: 'auto'
  //       },
  //       {
  //         element: document.querySelector('.cpc-deal-back-link a'),
  //         intro: "Click here to go back to the list of deals.",
  //         position: 'auto'
  //       }
  //     ]
  //   });

  //   intro.start();

  //   intro.start().oncomplete(function() {
  //     window.location.href = document.querySelector('.cpc-deal-back-link a').href + '?third-tutorial-page';
  //   });

  //   intro.onexit(deleteTutorialCookie);
  // }

  function introThirdPage(){
    var intro = introJs();
    intro.setOptions({
      steps: [
        {
          element: document.querySelector('form[name="cpc-filter-deals"]'),
          intro: 'You can filter existing deals using this bar. You can filter the deals by their category. ',
          position: 'auto'
        },
        {
          element: document.querySelector('input[name="FilterSearchKeywordValue"]'),
          intro: "You can search deals by keyword.",
          position: 'auto'
        },
        {
          element: document.querySelector('input[name="FilterPostalZipValue"]'),
          intro: "Filter by location here. By default, this will use the postal/zip code assigned to your account.",
          position: 'auto'
        },
        {
          element: document.querySelector('.cpc-magenta-btn.cpc-filter-btn'),
          intro: "Click here to filter.",
          position: 'auto'
        },
        {
          intro: "Lastly there is the club information form",
          position: 'auto'
        },
      ]
    });

    intro.start().oncomplete(function() {
        window.location.href = '/my-account' + '?fourth-tutorial-page';
      });

      intro.onexit(deleteTutorialCookie);
  }

  function introFourthPage(){
    var intro = introJs();
    intro.setOptions({
      steps: [
        {
          element: document.querySelector('form[name="cpc_update_club_info"]'),
          intro: "This form is used to edit the information related to your club.",
          position: 'auto'
        },
        {
          element: document.querySelector('input[name="FaviconImageValue"]'),
          intro: "You can assign a favicon for your users here. Please ensure the image you upload is a png.",
          position: 'auto'
        },
        {
          element: document.querySelector('input[name="ClubImageValue"]'),
          intro: 'Change the logo of your club here.',
          position: 'auto'
        },
        {
          element: document.querySelectorAll('img')[0],
          intro: "If you assign a club logo, it will be set here if the user is on that subdomain.",
          position: 'auto'
        },
        {
            element: document.querySelector('input[name="CompnanyWebsiteValue"]'),
            intro: "Assigning your company websites.",
            position: 'auto'
        },
        {
            element: document.querySelector('.feum-file-upload div.row'),
            intro: "You can set your brand colors here. This will change the colors used when users are logged in.",
            position: 'auto'
        },
        {
            element: document.querySelectorAll('.feum-cpc-form-horizontal-form-group')[3],
            intro: "You can add your Facebook link",
            position: 'auto'
        },
        {
            element: document.querySelectorAll('.feum-cpc-form-horizontal-form-group')[4],
            intro: "Twitter link",
            position: 'auto'
        },
        {
            element: document.querySelectorAll('.feum-cpc-form-horizontal-form-group')[5],
            intro: "and Instagram link here",
            position: 'auto'
        },
        {
            element: document.querySelector('.cpc-footer-links'),
            intro: "Once you have set your social media links, users can click the social media icons in the footer and be directed to those web pages.",
            position: 'auto'
        },
        {
            intro: "This ends the tutorial for corporateplusclub. Please contact <a c href='mailto:info@corporateplusclub.com'>info@corporateplusclub.com</a> if you have any questions or concerns.",
            position: 'auto'
        }
      ]
    });

    intro.start().oncomplete(markTutorialComplete);
  }

console.log( document.cookie.split(';') );
if(window.location.search.substr(1).indexOf('login=false') === -1){
  if (document.cookie.split(';').filter(function(item) {
    //this cookie will be created on new register and first time logins
      return item.trim().indexOf('cpc_is_tutorial_complete=') == 0
  }).length) {
    startIntro();
  }
}

if( showTutorialParam.length != 0 ){

    if(RegExp('second-tutorial-page', 'gi').test(window.location.search)) {
        introCreateDeal();
    }

    if(RegExp('third-tutorial-page', 'gi').test(window.location.search)) {
        introThirdPage();
    }

    if(RegExp('fourth-tutorial-page', 'gi').test(window.location.search)) {
        introFourthPage();
    }
    
}

jQuery('.btn-complete-tutorial').on('click', function() {
  document.cookie = "cpc_is_tutorial_complete=false; expires="+ Math.floor(new Date().getTime() / 1000) + (10 * 365 * 24 * 60 * 60) + "; path=/";
});