// TAKEN FROM: https://stackoverflow.com/questions/21825157/internet-explorer-11-detection
if( navigator.userAgent.indexOf('MSIE')!==-1 || navigator.appVersion.indexOf('Trident/') > -1 ){
    tkObjectFit();
}

// TAKEN FROM: https://medium.com/@lootcity/css-object-fit-fallback-f5b015767433
function tkObjectFit() {
    jQuery('img.objFit').each(function(){
        var imgSrc = jQuery(this).attr('src');
        var fitType = 'cover';
        if(jQuery(this).data('fit-type')) {
            fitType = jQuery(this).data('fit-type');
        }
        jQuery(this).parent().css({ 'background' : 'transparent url("'+imgSrc+'") no-repeat center center/'+fitType, });
        jQuery(this).remove(); });
}