/**
 * SINCE WE ARE USING A VERSION OF BOOTSTRAP PRIOR TO v4.3, LOGIC TO HAVE A TOAST POP UP AT BOTTOM OF SCREEN
 */
jQuery(document).ready(function($) {
    var toasts = $('.cpc-toast');

    toasts.each( function(){

       

        if( $( this ).hasClass( 'cpc-toast-onload' ) ){

            var $_this = $( this );


            $_this.addClass( "show" );

            setTimeout( function(){ 

                $_this.removeClass( "show" ); 

            }, 2000 );

        }
    });
        
});