document.addEventListener("DOMContentLoaded", function(){

    var youtubeVideoWidget = document.querySelectorAll('.youtube-video-widget');
    var youtubeVideo;
    var youtubeVideoImg;
    var youtubeVideoHtml = document.createElement('iframe');

    for( var i = 0; i < youtubeVideoWidget.length; i++ ){

        youtubeVideo = youtubeVideoWidget[i].querySelector('.youtube-video-widget-overlay');
        youtubeVideoImg = youtubeVideoWidget[i].querySelector('.youtube-video-widget-img');

        youtubeVideoWidget[i].addEventListener( "click", function(){
            youtubeVideoHtml.setAttribute('src' , `https://www.youtube.com/embed/${ encodeURI( youtubeVideo.dataset.youtubeId ) }?autoplay=1&controls=0&autohide=1&border=0&wmode=opaque&enablejsapi=1`);
            youtubeVideoHtml.setAttribute('frameborder', 0 );
            youtubeVideoHtml.setAttribute('width', '100%' );
            youtubeVideoHtml.setAttribute('height', youtubeVideoImg.height );

            this.replaceChild( youtubeVideoHtml, youtubeVideo );
        });
    }

    // youtubeVideoWidget.forEach( (elem) => {

    //     youtubeVideo = elem.querySelector('.youtube-video-widget-overlay');
    //     youtubeVideoImg = elem.querySelector('.youtube-video-widget-img');

    //     elem.onclick = () => {
    //         youtubeVideoHtml.setAttribute('src' , `https://www.youtube.com/embed/${ encodeURI( youtubeVideo.dataset.youtubeId ) }?autoplay=1&controls=0&autohide=1&border=0&wmode=opaque&enablejsapi=1`);
    //         youtubeVideoHtml.setAttribute('frameborder', 0 );
    //         youtubeVideoHtml.setAttribute('width', '100%' );
    //         youtubeVideoHtml.setAttribute('height', youtubeVideoImg.height );

    //         console.log( elem );

    //         // elem.replaceChild( youtubeVideoHtml, youtubeVideo );
    //     };
    // });
});